@import '~rsuite/styles/index.less';

@panelBlue: rgba(143, 214, 225);
@panelGreen: rgb(152, 175, 106);
@logoRed: rgb(99,63,56);
@cardBlue: rgb(125,142,176);
@secondCardBlue: rgb(115, 169, 173);
@secondLogoBlack: black;
@secondPanelGreen: rgb(179, 200, 144);
@white: rgb(243,242,237);
.logoBackground {
  background-color: @logoRed;
}

.horizontalCenterHeader {
  align-items: center;
  text-align: center;
  margin-bottom: .5em;
}

.fontColor {
  color: black;
}

.cardSpacing {
  margin-right: 1em;


}

.cardStyling{
  background-color: @white;
  color: black;
  border-color: @logoRed;
}

.aboutMe{
  margin-top: 2em;
  background-color: @white;
  color: black;
  border-color: @logoRed;
}

.logo {
  max-height: fit-content;
  max-width: fit-content;
}

body {
    background-color: @secondPanelGreen;
}

